/**
 * File box-slider.js.
 *
 * Handles slider for boxes and it will slide as per below parameter passed.
 */
(function ($) {
    'use strict';
    var boxSliderWrp = [];
    var boxSliderTwoWrp = [];

    // One page navigation on page load.
    let hashKeyCat = $(location).prop('hash') || '';
        hashKeyCat = hashKeyCat.replace('#', '');
    if ( '' != hashKeyCat && undefined != hashKeyCat ) {
        if ( $( '#' + hashKeyCat ).length > 0 ) {
            var headerHeight = $('header nav.navbar').outerHeight();
            $('html,body').animate({
                scrollTop: $( '#' + hashKeyCat ).offset().top - headerHeight
            }, 2500);
        }
    }

    $(document).ready(function () {

        // equal height
        var matchHeight = function () {

            function init() {
                matchHeight();
            }

            function matchHeight() {
                var className = $('.box-slider__content-wrap, .box-slider-two__item-col');
                var groupHeights = [];

                className.css('min-height', 'auto');

                className.each(function () {
                    groupHeights.push($(this).outerHeight());
                });

                var maxHeight = Math.max.apply(null, groupHeights);
                className.css('min-height', maxHeight);
            };

            return {
                init: init
            };

        }();

        // functions calls
        boxSliderBlock();

        // box slider two functions calls
        boxSliderTwoBlock();

        // equal height
        matchHeight.init();

        // resize functions calls
        $(window).on('resize', function () {
            matchHeight.init();
            boxSliderBlock();
            boxSliderTwoBlock();
        });

        jQuery(document).on('click', '.categories-list-topper li a', function () {
            var target = $(this).attr('href') || '';
            if ('' != target && $(target).length > 0) {
                $('.categories-list-topper li').removeClass('active');
                $(this).parent().addClass('active');
                $('html, body').stop().animate({ 'scrollTop': $(target).offset().top - 100 });
            }
            return false;
        });

        $('.banner-slider').each(function (index) {
            let autoplay = $(this).attr('data-slide-autoplay') || false;
            let dots = $(this).attr('data-slide-dots') || false;
            let arrows = $(this).attr('data-slide-arrows') || false;
            let loopSlide = $(this).attr('data-slide-loop') || false;
            jQuery(`.banner-slider .banner-wrap`).slick({
                autoplay: 'true' === autoplay ? true : false,
                //pauseOnHover: 'true' === autoplay ? true : false,
                dots: 'true' === dots ? true : false,
                infinite: 'true' === loopSlide ? true : false,
                arrows: 'true' === arrows ? true : false,
                slidesToShow: 1,
                slidesToScroll: 1,
                // swipeToSlide: true,
                // draggable: false,
            });
        });

        // One page navigation on button click
        $(document).on('click', '.btn-main, .btn-main a, .btn-link, .btn-link a, .link-col', function () {
            var hasId = $(this).attr('href') || '';
            if ('' == hasId) {
                hasId = $('a', this).attr('href') || '';
            }
            if ('' != hasId && hasId.indexOf('#') > -1 && $(hasId).length > 0) {
                var headerHeight = $('header nav.navbar').outerHeight();
                $('html,body').animate({
                    scrollTop: $(hasId).offset().top - headerHeight
                }, 2500);
                return false;
            }
        });
    });

    function boxSliderBlock() {
        $('.box-slider').each(function (index) {
            let windowWidth = $(window).outerWidth();
            let sliderMain = $(this).find('.box-slider-main');
            let autoplay = sliderMain.attr('data-slide-autoplay') || false;
            let arrows = sliderMain.attr('data-slide-arrows') || false;
            let loopSlide = sliderMain.attr('data-slide-loop') || false;
            let slideSlidesToShow = sliderMain.attr('data-slide-show') || 6;

            let sliderConfig = {
                autoplay: 'true' === autoplay ? true : false,
                autoplaySpeed: 3000,
                //pauseOnHover: 'true' === autoplay ? true : false,
                dots: false,
                infinite: 'true' === loopSlide ? true : false,
                arrows: 'true' === arrows ? true : false,
                slidesToShow: parseInt(slideSlidesToShow),
                slidesToScroll: 1,
                // swipeToSlide: false,
                // draggable: false,
                responsive: [
                    {
                        breakpoint: 1025,
                        settings: {
                            slidesToShow: 3
                        }
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 2
                        }
                    },
                    {
                        breakpoint: 479,
                        settings: {
                            slidesToShow: 1
                        }
                    },
                ]
            };

            if (boxSliderWrp[index]) {
                boxSliderWrp[index].slick('refresh');
            } else {
                boxSliderWrp[index] = sliderMain.slick(sliderConfig);
            }
        });
    }

    // box slider two functions calls
    function boxSliderTwoBlock() {
        $('.box-slider-two').each(function (index) {
            let windowWidth = $(window).outerWidth();
            let sliderTwoMain = $(this).find('.box-slider-two-main');
            let autoplay = sliderTwoMain.attr('data-slide-autoplay') || false;
            let arrows = sliderTwoMain.attr('data-slide-arrows') || false;
            let loopSlide = sliderTwoMain.attr('data-slide-loop') || false;
            let slideTwoSlidesToShow = sliderTwoMain.attr('data-slide-show') || 6;

            let sliderConfig = {
                autoplay: 'true' === autoplay ? true : false,
                autoplaySpeed: 3000,
                //pauseOnHover: 'true' === autoplay ? true : false,
                dots: false,
                infinite: 'true' === loopSlide ? true : false,
                arrows: 'true' === arrows ? true : false,
                slidesToShow: parseInt(slideTwoSlidesToShow),
                slidesToScroll: 1,
                // swipeToSlide: false,
                // draggable: false,
                responsive: [
                    {
                        breakpoint: 1025,
                        settings: {
                            slidesToShow: 3
                        }
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 2
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 1
                        }
                    },
                ]
            };

            if (boxSliderTwoWrp[index]) {
                boxSliderTwoWrp[index].slick('refresh');
            } else {
                boxSliderTwoWrp[index] = sliderTwoMain.slick(sliderConfig);
            }
        });
    }

    // Added class for the width setting in the core group and columns block.
    $('#blog-wrapper .entry-content > .wp-block-columns').addClass('has-max-width');
    $('#blog-wrapper .entry-content > .wp-block-group').addClass('has-max-width');
    $('#blog-wrapper .entry-content > .wp-block-uagb-container').addClass('has-max-width');

})(jQuery);